import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const settings_routes: RouteConfig = {
  path: "/settings",
  component: () => import("@/views/settings/SettingsWrapper.vue"),
  meta: {
    user_state: [UserState.CANDIDATE, UserState.RECRUITER, UserState.INSTITUTE]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "accounts-setting"
      }
    },
    {
      path: "account",
      name: "accounts-setting",
      component: () => import("@/views/settings/Account.vue"),
      meta: {
        user_state: [
          UserState.CANDIDATE,
          UserState.RECRUITER,
          UserState.INSTITUTE
        ]
      }
    }
  ]
};
