import {
  LOGOUT_USER,
  AUTH_LOADING,
  SET_COMPANY,
  SET_USER,
  SET_USER_PROPERTY,
  USER_PROFILE,
  USER_STATE,
  RESET_PASSWORD_TOKEN,
  RESET_PASS_USER_EMAIL
} from "@/store/modules/auth/constants";
import {
  AuthState,
  Company,
  SetUserPropertyPayload,
  UserState
} from "@/store/modules/auth/interfaces";
import { User } from "@/interfaces/data_objects/user";
import { Candidates } from "@/interfaces/data_objects/candidates";

export default {
  [AUTH_LOADING]: (state: AuthState, payload: boolean): void => {
    state.loading = payload;
  },
  [SET_USER]: (state: AuthState, payload: User): void => {
    state.user = payload;
  },
  [SET_USER_PROPERTY]: (
    state: AuthState,
    payload: SetUserPropertyPayload
  ): void => {
    const obj_name = payload.obj_name as keyof typeof state.user;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.user[obj_name] = payload.value;
  },
  [SET_COMPANY]: (state: AuthState, payload: Company | null): void => {
    state.company = payload;
  },
  [USER_STATE]: (state: AuthState, payload: UserState): void => {
    state.user_state = payload;
  },
  [USER_PROFILE]: (
    state: AuthState,
    payload: Candidates.CandidateDetails | null
  ): void => {
    state.user_profile = payload;
  },
  [RESET_PASSWORD_TOKEN]: (state: AuthState, payload: string | null): void => {
    state.reset_password_token = payload;
  },
  [RESET_PASS_USER_EMAIL]: (state: AuthState, payload: string | null): void => {
    state.email_for_reset_password = payload;
  },
  [LOGOUT_USER]: (state: AuthState): void => {
    state.user = null;
    state.user_state = UserState.UNAUTHENTICATED;
    state.company = null;
    state.token = null;
    state.user_profile = null;
    state.loading = false;
  }
};
