import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const institute_students: RouteConfig = {
  path: "students",
  name: "institute-students",
  component: () => import("@/views/modules/institute/students/Wrapper.vue"),
  meta: {
    user_state: [UserState.INSTITUTE]
  }
};
