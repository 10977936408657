import {
  ForgotPasswordPayload,
  LoginActionPayload,
  ResetPasswordPayload,
  SignupActionPayload,
  UpdateCompanyPayload,
  RefreshTokenPayload
} from "@/store/modules/auth/interfaces";
import axios, { AxiosResponse } from "axios";
import {
  FORGOT_PASSWORD,
  GET_USER,
  GOOGLE_AUTH,
  LINKEDIN_AUTH,
  LOGIN,
  RESET_PASSWORD,
  SIGNUP,
  UPDATE_COMPANY_DETAILS,
  UPDATE_PROFILE,
  UPDATE_USER_DETAILS,
  USER_PROFILE,
  VERIFY_EMAIL,
  REFRESH_TOKEN,
  LOGOUT_USER
} from "@/store/urls/auth";
import { Candidates } from "@/interfaces/data_objects/candidates";

export const login_user = async (
  payload: LoginActionPayload
): Promise<AxiosResponse> => {
  return await axios.post(LOGIN, payload);
};

export const signup_user = async (
  payload: SignupActionPayload
): Promise<AxiosResponse> => {
  return await axios.post(SIGNUP, payload).then((response) => response.data);
};

export const verify_email = async (payload: string): Promise<AxiosResponse> => {
  return await axios.get(`${VERIFY_EMAIL}${payload}`);
};

export const get_user = async (id?: number): Promise<AxiosResponse> => {
  return await axios.get(`${GET_USER}${id ? id : ""}`);
};

export const google_auth = async (): Promise<void> => {
  window.location.href = GOOGLE_AUTH;
};

export const linkedin_auth = async (): Promise<void> => {
  window.location.href = LINKEDIN_AUTH;
};

export const get_user_profile = async (id?: number): Promise<AxiosResponse> => {
  return await axios.get(`${USER_PROFILE}${id ? id : ""}`);
};

export const update_user_details = async (
  payload: SignupActionPayload
): Promise<AxiosResponse> => {
  return axios.patch(UPDATE_USER_DETAILS, payload);
};

export const reset_password = async (
  payload: ResetPasswordPayload
): Promise<AxiosResponse> => {
  return axios.post(RESET_PASSWORD, payload);
};

export const forgot_password = async (
  payload: ForgotPasswordPayload
): Promise<AxiosResponse> => {
  return axios.post(FORGOT_PASSWORD, payload);
};

export const update_user_profile = async (
  payload: Candidates.CandidateDetails
): Promise<AxiosResponse> => await axios.patch(UPDATE_PROFILE, payload);

export const update_user_company = async (
  payload: UpdateCompanyPayload
): Promise<AxiosResponse> => await axios.patch(UPDATE_COMPANY_DETAILS, payload);

export const refresh_token = async (
  payload: RefreshTokenPayload
): Promise<AxiosResponse> => {
  return await axios.post(REFRESH_TOKEN, payload);
};

export const logout_user = async (user_id: number): Promise<AxiosResponse> => {
  return await axios.get(`${LOGOUT_USER}${user_id}`);
};
