import { Roles } from "@/interfaces/data_objects/roles";
import { Jobs } from "@/interfaces/data_objects/jobs";

/**
 * Common state main interface
 */
export interface CommonState {
  loading: boolean;
  sectors: SectorData[];
  roles: Roles[];
  searched_job: Jobs | null;
  view_job_state: ViewJobState;
}

export interface SectorData {
  sector: string;
}

export interface FetchSkillsGroupActionPayload {
  skills: string[];
}

export enum ViewJobState {
  DEFAULT,
  JOB_LISTING,
  STANDARDISED,
  STANDARDISED_JOB_POST,
  LINKEDIN_JOB_POST,
  FILE_JOB_POST,
  TEXT_JOB_POST,
  EDIT_TEXT_JOB_POST
}

export interface GetJobMatchActionPayload {
  cv_id: string;
  job_id: number;
}

export interface SkillSearchPayload {
  page: number;
  limit_per_page: number;
  skill_name: string;
}
