import {
  ALL_INSTITUTES,
  COMPARISON_OTHER_SKILLS,
  COMPARISON_OTHER_SKILLS_LOADING,
  DEGREE_GAP_LOADING,
  DEGREE_JOB_COMPARISON,
  DEGREE_JOB_FILE_COMPARISON,
  FETCH_INSTITUTE_PROGRAM,
  FETCH_INSTITUTES,
  GLOBAL_DEGREE_LOADING,
  GLOBAL_DEGREE_SKILLS,
  INIT_INSTITUTE_STATE,
  INSTITUTE_STATE_LOADING,
  NATIONAL_DEGREE_LOADING,
  NATIONAL_DEGREE_SKILLS,
  NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS,
  NATIONAL_GLOBAL_COMPARISON,
  RESET_INSTITUTE_STATE,
  UPLOAD_GLOBAL_DEGREE,
  UPLOAD_NATIONAL_DEGREE,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/institute/constants";
import { Commit, Dispatch } from "vuex";
import {
  degree_job_comparison,
  fetch_degree_top_jobs,
  fetch_institute_program,
  fetch_institutes,
  institutes_compare,
  upload_program,
  upskill_courses
} from "@/store/apis/instutute";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { Programs } from "@/interfaces/data_objects/programs";
import {
  DegreeJobComparisonPayload,
  DegreeTopJobsPayload,
  NationalGlobalComparisonPayload,
  UpskillCoursesPayload
} from "@/store/modules/institute/interfaces";
import router from "@/router";
import { UPLOAD_FILE } from "@/store/modules/common/constants";
export default {
  [INIT_INSTITUTE_STATE]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<void> => {
    await dispatch(FETCH_INSTITUTES, "local");
    await dispatch(FETCH_INSTITUTES, "global");
  },
  /**
   * Action to fetch all institutes & setting institutes
   * @param commit
   */
  [FETCH_INSTITUTES]: async (
    { commit }: { commit: Commit },
    institute_type: string
  ): Promise<void> => {
    commit(INSTITUTE_STATE_LOADING, true);
    const response = await fetch_institutes(institute_type);
    commit(ALL_INSTITUTES, response.data.data);
    commit(INSTITUTE_STATE_LOADING, false);
  },
  [FETCH_INSTITUTE_PROGRAM]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    institute_id: number
  ): Promise<Programs[]> => {
    try {
      commit(INSTITUTE_STATE_LOADING, true);
      const response = await fetch_institute_program(institute_id);
      commit(INSTITUTE_STATE_LOADING, false);
      return response.data.data;
    } catch (err) {
      commit(INSTITUTE_STATE_LOADING, false);
      commit(ROOT_ERROR, err, { root: true });
      return [];
    }
  },
  [DEGREE_JOB_FILE_COMPARISON]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: FormData
  ): Promise<void> => {
    try {
      commit(NATIONAL_DEGREE_LOADING, true);
      commit(NATIONAL_DEGREE_SKILLS, null);
      // Fetching degree skills
      const response = await upload_program(payload);
      commit(NATIONAL_DEGREE_SKILLS, response.data.data);
      commit(NATIONAL_DEGREE_LOADING, false);
      // Fetching top jobs with industries
      commit(DEGREE_GAP_LOADING, true);
      const top_jobs_payload: DegreeTopJobsPayload = {
        response_id: response.data.data.info.file_id,
        total_count: 3,
        type: "file"
      };
      const top_jobs_response = await fetch_degree_top_jobs(top_jobs_payload);
      commit(NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS, top_jobs_response.data.data);
      commit(DEGREE_GAP_LOADING, false);
    } catch (e) {
      commit(NATIONAL_DEGREE_LOADING, false);
      commit(NATIONAL_DEGREE_SKILLS, null);
      commit(DEGREE_GAP_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      await router.back();
    }
  },
  [DEGREE_JOB_COMPARISON]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: DegreeJobComparisonPayload
  ): Promise<void> => {
    try {
      commit(NATIONAL_DEGREE_LOADING, true);
      commit(NATIONAL_DEGREE_SKILLS, null);
      const response = await degree_job_comparison(payload);
      commit(NATIONAL_DEGREE_SKILLS, response.data.data);
      commit(NATIONAL_DEGREE_LOADING, false);
      // Fetching top jobs with industries
      commit(DEGREE_GAP_LOADING, true);
      const top_jobs_payload: DegreeTopJobsPayload = {
        total_count: 3,
        type: "list",
        ref_institute_id: payload.ref_institute_id,
        ref_program_id: payload.ref_program_id
      };
      const top_jobs_response = await fetch_degree_top_jobs(top_jobs_payload);
      commit(NATIONAL_DEGREE_TOPJOBS_WITH_SECTORS, top_jobs_response.data.data);
      commit(DEGREE_GAP_LOADING, false);
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      await router.back();
      commit(NATIONAL_DEGREE_LOADING, false);
      commit(NATIONAL_DEGREE_SKILLS, null);
      commit(DEGREE_GAP_LOADING, false);
    }
  },
  [NATIONAL_GLOBAL_COMPARISON]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: NationalGlobalComparisonPayload
  ): Promise<void> => {
    try {
      commit(COMPARISON_OTHER_SKILLS_LOADING, true);
      const response = await institutes_compare(payload);
      commit(COMPARISON_OTHER_SKILLS, response.data.data);
      commit(COMPARISON_OTHER_SKILLS_LOADING, false);
    } catch (e) {
      commit(COMPARISON_OTHER_SKILLS_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
    }
  },
  /**
   * FUnction to upload degree file or degree content and setting NATIONAL_DEGREE_SKILLS state variable
   * If failed to extract skills => RESET_INSTITUTE_STATE & back to previous page
   * @param commit
   * @param dispatch
   * @param {FormData} payload
   * @return boolean => true => skills found else false
   */
  [UPLOAD_NATIONAL_DEGREE]: async (
    {
      commit,
      dispatch
    }: {
      commit: Commit;
      dispatch: Dispatch;
    },
    payload: FormData
  ): Promise<boolean> => {
    try {
      commit(NATIONAL_DEGREE_LOADING, true);
      commit(NATIONAL_DEGREE_SKILLS, null);
      const local_type = payload.get("local_type");
      let extracted_skills;
      if (local_type === "file") {
        // Uploading file & extracting skills
        extracted_skills = await dispatch(`common/${UPLOAD_FILE}`, payload, {
          root: true
        });
      } else {
        const ref_institute_id = payload.get("ref_institute_id") as string;
        const ref_program_id = payload.get("ref_program_id") as string;
        const institute_id = parseInt(ref_institute_id);
        const program_id = parseInt(ref_program_id);
        extracted_skills = await degree_job_comparison({
          ref_institute_id: institute_id,
          ref_program_id: program_id
        });
      }
      // If national degree extracted skills found successfully
      if (extracted_skills)
        commit(NATIONAL_DEGREE_SKILLS, extracted_skills.data.data);
      // If national degree extracted skills not found
      else {
        await router.back(); // Navigate to previous page
        // Display error snackbar
        commit(ROOT_ERROR, "Failed to get degree skills", { root: true });
        commit(RESET_INSTITUTE_STATE); // Reset institute state
        return false;
      }
      commit(NATIONAL_DEGREE_LOADING, false);
      return true;
    } catch (e) {
      await router.back(); // Navigate to previous page
      // Display error snackbar
      commit(ROOT_ERROR, "Failed to get degree skills", { root: true });
      commit(RESET_INSTITUTE_STATE); // Reset institute state
      return false;
    }
  },
  /**
   * FUnction to upload degree file or degree content and setting NATIONAL_DEGREE_SKILLS state variable
   * If failed to extract skills => RESET_INSTITUTE_STATE & back to previous page
   * @param commit
   * @param dispatch
   * @param {FormData} payload
   * @return boolean => true => skills found else false
   */
  [UPLOAD_GLOBAL_DEGREE]: async (
    {
      commit,
      dispatch
    }: {
      commit: Commit;
      dispatch: Dispatch;
    },
    payload: FormData
  ): Promise<boolean> => {
    try {
      commit(GLOBAL_DEGREE_LOADING, true);
      commit(GLOBAL_DEGREE_SKILLS, null);
      const local_type = payload.get("local_type");
      let extracted_skills;
      if (local_type === "file") {
        // Uploading file & extracting skills
        extracted_skills = await dispatch(`common/${UPLOAD_FILE}`, payload, {
          root: true
        });
      } else {
        const ref_institute_id = payload.get("ref_institute_id") as string;
        const ref_program_id = payload.get("ref_program_id") as string;
        const institute_id = parseInt(ref_institute_id);
        const program_id = parseInt(ref_program_id);
        extracted_skills = await degree_job_comparison({
          ref_institute_id: institute_id,
          ref_program_id: program_id
        });
      }
      // If national degree extracted skills found successfully
      if (extracted_skills) {
        commit(GLOBAL_DEGREE_SKILLS, extracted_skills.data.data);
      }
      // If national degree extracted skills not found
      else {
        await router.back(); // Navigate to previous page
        // Display error snackbar
        commit(ROOT_ERROR, "Failed to get degree skills", { root: true });
        commit(RESET_INSTITUTE_STATE); // Reset institute state
        return false;
      }
      commit(GLOBAL_DEGREE_LOADING, false);
      return true;
    } catch (e) {
      await router.back(); // Navigate to previous page
      // Display error snackbar
      commit(ROOT_ERROR, "Failed to get degree skills", { root: true });
      commit(RESET_INSTITUTE_STATE); // Reset institute state
      return false;
    }
  },
  /**
   * Function to get upskill courses & set UPSKILL_COURSES store variable
   * @param commit
   * @param {UpskillCoursesPayload} payload
   */
  [UPSKILL_COURSES]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: UpskillCoursesPayload
  ): Promise<void> => {
    try {
      commit(UPSKILL_COURSES_LOADING, true);
      commit(UPSKILL_COURSES, null);
      const response = await upskill_courses(payload);
      commit(UPSKILL_COURSES, response.data.data);
      commit(UPSKILL_COURSES_LOADING, false);
    } catch (e) {
      commit(UPSKILL_COURSES_LOADING, false);
      commit(UPSKILL_COURSES, null);
      commit(ROOT_ERROR, e, { root: true });
      await router.back();
    }
  }
};
