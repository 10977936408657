import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const syllabi_comparison: RouteConfig = {
  path: "comparison",
  component: () =>
    import("@/views/modules/institute/syllabi_comparison/Wrapper.vue"),
  meta: {
    user_state: [UserState.INSTITUTE]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "select-operation"
      }
    },
    {
      path: "select",
      name: "select-operation",
      component: () =>
        import(
          "@/views/modules/institute/syllabi_comparison/SelectOperation.vue"
        ),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    {
      path: "national-global",
      name: "national-global-comparison",
      component: () =>
        import(
          "@/views/modules/institute/syllabi_comparison/NationalGlobalComparisonChoice.vue"
        ),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    {
      path: "national-global/result",
      name: "national-global-comparison-result",
      component: () =>
        import(
          "@/views/modules/institute/syllabi_comparison/NationalGlobalComparison.vue"
        ),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    {
      path: "national-global/result/upskill",
      name: "upskill-courses-national-global",
      component: () =>
        import(
          "@/views/modules/institute/syllabi_comparison/UpskillCourses.vue"
        ),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    {
      path: "national-job",
      name: "national-job-comparison",
      component: () =>
        import(
          "@/views/modules/institute/syllabi_comparison/NationalJobComparisonChoice.vue"
        ),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    {
      path: "national-job/result",
      name: "national-job-comparison-result",
      component: () =>
        import(
          "@/views/modules/institute/syllabi_comparison/NationalJobComparison.vue"
        ),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    },
    {
      path: "national-job/result/upskill",
      name: "upskill-courses-national-job",
      component: () =>
        import(
          "@/views/modules/institute/syllabi_comparison/UpskillCourses.vue"
        ),
      meta: {
        user_state: [UserState.INSTITUTE]
      }
    }
  ]
};
