import {
  AllCandidates,
  CandidateViewPayload,
  JobsWithCandidates,
  RecruiterState,
  ViewCandidatesDynamically
} from "@/store/modules/recruiter/interfaces";
import {
  RECRUITER_LOADING,
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  TOP_JOBS_WITH_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY,
  FETCH_MATCHING_CANDIDATES,
  RECOMMEND_JOB_PROFILES,
  CANDIDATE_VIEW_PAYLOAD,
  ALL_CANDIDATES
} from "@/store/modules/recruiter/constants";
import { Jobs } from "@/interfaces/data_objects/jobs";

export default {
  [RECRUITER_LOADING]: (state: RecruiterState): boolean => state.loading,
  [JOBS_POSTED_BY_RECRUITER_LOADING]: (state: RecruiterState): boolean =>
    state.jobs_posted_loading,
  [JOBS_POSTED_BY_RECRUITER]: (
    state: RecruiterState
  ): { total: number; results: JobsWithCandidates[] } => state.jobs_posted,
  [TOP_JOBS_WITH_CANDIDATES]: (
    state: RecruiterState
  ): { total: number; results: JobsWithCandidates[] } => state.top_jobs_posted,
  [VIEW_CANDIDATES_DYNAMICALLY]: (
    state: RecruiterState
  ): ViewCandidatesDynamically => state.view_candidates_dynamically,
  [FETCH_MATCHING_CANDIDATES]:
    (state: RecruiterState) =>
    (job_id: number, top_jobs: false): JobsWithCandidates | undefined => {
      if (top_jobs)
        return state.top_jobs_posted.results.find(
          (v: Jobs) => v.jid === job_id
        );
      else return state.jobs_posted.results.find((v: Jobs) => v.jid === job_id);
    },
  [RECOMMEND_JOB_PROFILES]: (state: RecruiterState): Jobs[] =>
    state.recommend_job_profiles,
  [CANDIDATE_VIEW_PAYLOAD]: (
    state: RecruiterState
  ): CandidateViewPayload | null => state.candidate_view_payload,
  [ALL_CANDIDATES]: (state: RecruiterState): AllCandidates =>
    state.all_candidates
};
