import {
  JOB_ID_FOR_UPSKILL,
  RESET_CANDIDATE_STATE,
  SEARCHED_MATCHING_JOBS,
  SET_CANDIDATE_LOADING,
  SET_JOBS_LOADING,
  SET_MATCHING_JOBS,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/candidates/constants";
import {
  CandidateState,
  MatchingJob,
  SearchedMatchingJobsPayload
} from "@/store/modules/candidates/interfaces";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";

export default {
  [SET_CANDIDATE_LOADING]: (state: CandidateState, payload: boolean): void => {
    state.loading = payload;
  },
  [SET_JOBS_LOADING]: (state: CandidateState, payload: boolean): void => {
    state.jobs_loading = payload;
  },
  [UPSKILL_COURSES_LOADING]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.upskill_courses_loading = payload;
  },
  [SET_MATCHING_JOBS]: (
    state: CandidateState,
    payload: MatchingJob[]
  ): void => {
    state.matching_jobs = payload;
  },
  [SEARCHED_MATCHING_JOBS]: (
    state: CandidateState,
    payload: SearchedMatchingJobsPayload
  ): void => {
    state.searched_matching_jobs = payload;
  },
  [UPSKILL_COURSES]: (
    state: CandidateState,
    payload: UpskillCourses.UpskillCoursesRoot | null
  ): void => {
    state.upskill_courses = payload;
  },
  [JOB_ID_FOR_UPSKILL]: (
    state: CandidateState,
    payload: number | null
  ): void => {
    state.job_id_for_upskill = payload;
  },
  [RESET_CANDIDATE_STATE]: (state: CandidateState): void => {
    state.loading = false;
    state.jobs_loading = false;
    state.matching_jobs = [];
    state.upskill_courses = null;
    state.upskill_courses_loading = false;
    state.job_id_for_upskill = null;
    state.searched_matching_jobs = {
      jobs: [],
      title: "",
      active: false
    };
  }
};
