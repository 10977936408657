import axios, { AxiosResponse } from "axios";
import { InstituteApiUrls } from "@/store/urls/institute";
import {
  DegreeJobComparisonPayload,
  DegreeTopJobsPayload,
  NationalGlobalComparisonPayload,
  UpskillCoursesPayload
} from "@/store/modules/institute/interfaces";

export const fetch_institutes = async (
  institute_type: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${InstituteApiUrls.FETCH_INSTITUTES}?institute_type=${institute_type}`
  );
};

export const fetch_institute_program = async (
  institute_id: number
): Promise<AxiosResponse> => {
  return await axios.get(
    `${InstituteApiUrls.FETCH_INSTITUTE_PROGRAM}${institute_id}`
  );
};

export const upload_program = async (
  payload: FormData
): Promise<AxiosResponse> => {
  return await axios.post(`${InstituteApiUrls.UPLOAD_PROGRAM}`, payload);
};

export const degree_job_comparison = async (
  payload: DegreeJobComparisonPayload
): Promise<AxiosResponse> => {
  return await axios.post(`${InstituteApiUrls.DEGREE_JOB_COMPARISON}`, payload);
};

export const fetch_degree_top_jobs = async (
  payload: DegreeTopJobsPayload
): Promise<AxiosResponse> => {
  return await axios.post(`${InstituteApiUrls.DEGREE_TOP_JOBS}`, payload);
};

export const institutes_compare = async (
  payload: NationalGlobalComparisonPayload
): Promise<AxiosResponse> => {
  return await axios.post(`${InstituteApiUrls.INSTITUTES_COMPARE}`, payload);
};

export const upskill_courses = async (
  payload: UpskillCoursesPayload
): Promise<AxiosResponse> => {
  return await axios.post(InstituteApiUrls.UPSKILL_COURSES, payload);
};
