import {
  ACTIVATE_PUBLISHED_JOB,
  ALL_JOBS_COUNT,
  AUTOCOMPLETE_JOB_SEARCH,
  DELETE_JOB_SOFTLY,
  FETCH_MATCHING_CANDIDATES,
  GET_FAVORITE_CANDIDATES,
  INIT_RECRUITER,
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  MATCHING_CANDIDATES_BY_ID,
  MEMBERS_JOBS_STATISTICS,
  POST_JOB,
  RECRUITER_LOADING,
  REMOVE_TOP_JOBS_WITH_CANDIDATES,
  TOP_JOBS_WITH_CANDIDATES,
  UPDATE_FAVORITE_CANDIDATE,
  UPDATE_JOB,
  UPDATE_MATCHING_CANDIDATES,
  UPDATE_TOP_MATCHING_CANDIDATES
} from "@/store/modules/recruiter/constants";
import {
  activate_published_job,
  fetch_favorite_candidates,
  fetch_members_jobs_stats,
  fetch_total_jobs_count,
  get_jobs_posted_by_recruiter,
  get_matching_candidates_by_id,
  post_job,
  soft_delete_job,
  update_favorite_candidate,
  update_job
} from "@/store/apis/recruiter";
import { Commit, Dispatch } from "vuex";
import { Jobs } from "@/interfaces/data_objects/jobs";
import {
  GetJobsPostedPayload,
  MatchingCandidatesByIdPayload,
  MembersJobsStatistics,
  UpdateFavoriteCandidatePayload,
  UpdateJobPayload
} from "@/store/modules/recruiter/interfaces";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { ROOT_ERROR } from "@/store/modules/root/constants";
export default {
  [POST_JOB]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<unknown | null> => {
    try {
      commit(RECRUITER_LOADING, true);
      const response = await post_job(payload);
      commit(RECRUITER_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return null;
    }
  },
  /**
   * Function to get jobs posted by recruiter
   * Jobs return in pagination
   * After getting jobs loop each job and fetch applicants separately
   * @param payload
   */
  [JOBS_POSTED_BY_RECRUITER]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<void> => {
    commit(JOBS_POSTED_BY_RECRUITER_LOADING, true);
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    const response = await get_jobs_posted_by_recruiter(payload);
    let jobs = response.data.data;
    jobs = {
      results: jobs.results.map((v: Jobs) => ({
        ...v,
        matching_candidates: [],
        candidates_loading: true
      })),
      total: jobs.total
    };
    if (payload.dashboard) commit(TOP_JOBS_WITH_CANDIDATES, jobs);
    else commit(JOBS_POSTED_BY_RECRUITER, jobs);
    commit(JOBS_POSTED_BY_RECRUITER_LOADING, false);
  },
  /**
   * Function to get matching candidates/applicants against job id
   */
  [MATCHING_CANDIDATES_BY_ID]: async (
    { commit }: { commit: Commit },
    payload: MatchingCandidatesByIdPayload
  ): Promise<void> => {
    try {
      const response = await get_matching_candidates_by_id(payload);
      if (payload.dashboard) {
        commit(UPDATE_TOP_MATCHING_CANDIDATES, {
          job_id: payload.job_id,
          candidates: response.data.data.results
        });
      } else {
        commit(UPDATE_MATCHING_CANDIDATES, {
          job_id: payload.job_id,
          candidates: response.data.data.results
        });
      }
    } catch (e) {
      if (payload.dashboard) {
        commit(UPDATE_TOP_MATCHING_CANDIDATES, {
          job_id: payload.job_id,
          candidates: null
        });
      } else {
        commit(UPDATE_MATCHING_CANDIDATES, {
          job_id: payload.job_id,
          candidates: null
        });
      }
    }
  },
  /**
   * Function to softly delete job
   * @param job_id:number => jid to remove
   */
  [DELETE_JOB_SOFTLY]: async (
    { commit, getters }: { commit: Commit; getters: any },
    job_id: number
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await soft_delete_job({ job_id });
      // Checking if job exist in top jobs data set
      const top_posted_jobs = getters.TOP_JOBS_WITH_CANDIDATES;
      const exist_job = top_posted_jobs.results.find(
        (v: Jobs) => v.jid === job_id
      );
      // If job exist in top jobs data set => remove & decrement total count
      if (exist_job) {
        commit(REMOVE_TOP_JOBS_WITH_CANDIDATES, job_id);
      }
      // If job not exist in top jobs data set => decrement total count only
      else commit(REMOVE_TOP_JOBS_WITH_CANDIDATES, -100);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  /**
   * Function to get matching candidates/applicants
   */
  [FETCH_MATCHING_CANDIDATES]: async (
    { commit }: { commit: Commit },
    payload: MatchingCandidatesByIdPayload
  ): Promise<Candidates.Candidates[] | null> => {
    try {
      const response = await get_matching_candidates_by_id(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, null, { root: true });
      return null;
    }
  },
  /**
   * Function for autocomplete job search
   */
  [AUTOCOMPLETE_JOB_SEARCH]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<Jobs[]> => {
    try {
      const response = await get_jobs_posted_by_recruiter(payload);
      return response.data.data.results;
    } catch (e) {
      commit(ROOT_ERROR, null, { root: true });
      return [];
    }
  },
  /**
   * Initialize recruiter data
   */
  [INIT_RECRUITER]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      const fetch_top_candidates_obj = {
        page: 0,
        limit_per_page: 5,
        dashboard: true
      };
      await dispatch(JOBS_POSTED_BY_RECRUITER, fetch_top_candidates_obj);
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action to activate published job
   */
  [ACTIVATE_PUBLISHED_JOB]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await activate_published_job(job_id);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  /**
   * Function to fetch all jobs count
   */
  [ALL_JOBS_COUNT]: async (): Promise<number> => {
    try {
      return (await fetch_total_jobs_count()).data.data;
    } catch (e) {
      return 0;
    }
  },
  /**
   * Function to update job
   */
  [UPDATE_JOB]: async (
    { commit }: { commit: Commit },
    payload: UpdateJobPayload
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await update_job(payload);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  [MEMBERS_JOBS_STATISTICS]: async (
    { commit }: { commit: Commit },
    payload: MembersJobsStatistics
  ): Promise<any> => {
    try {
      const response = await fetch_members_jobs_stats(payload);
      return response.data.data;
    } catch (e) {
      return false;
    }
  },
  /**
   * For getting favorite candidates against job
   */
  [GET_FAVORITE_CANDIDATES]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<any> => {
    try {
      const response = await fetch_favorite_candidates(job_id);
      return response.data.data;
    } catch (e) {
      return [];
    }
  },
  /**
   * For updating favorite candidates against job
   */
  [UPDATE_FAVORITE_CANDIDATE]: async (
    { commit }: { commit: Commit },
    payload: UpdateFavoriteCandidatePayload
  ): Promise<any> => {
    try {
      await update_favorite_candidate(payload);
      return true;
    } catch (e) {
      return false;
    }
  }
};
