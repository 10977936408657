export const RECRUITER_LOADING = "RECRUITER_LOADING";

export const POST_JOB = "POST_JOB";

// Job listing with candidates
export const JOBS_POSTED_BY_RECRUITER_LOADING =
  "JOBS_POSTED_BY_RECRUITER_LOADING";

export const JOBS_POSTED_BY_RECRUITER = "JOBS_POSTED_BY_RECRUITER";

export const MATCHING_CANDIDATES_BY_ID = "MATCHING_CANDIDATES_BY_ID";

export const UPDATE_MATCHING_CANDIDATES = "UPDATE_MATCHING_CANDIDATES";

export const TOP_JOBS_WITH_CANDIDATES = "TOP_JOBS_WITH_CANDIDATES";
export const REMOVE_TOP_JOBS_WITH_CANDIDATES =
  "REMOVE_TOP_JOBS_WITH_CANDIDATES";

export const UPDATE_TOP_MATCHING_CANDIDATES = "UPDATE_TOP_MATCHING_CANDIDATES";

export const DELETE_JOB_SOFTLY = "DELETE_JOB_SOFTLY";

export const VIEW_CANDIDATES_DYNAMICALLY = "VIEW_CANDIDATES_DYNAMICALLY";

// For fetching matching candidates/applicants
export const FETCH_MATCHING_CANDIDATES = "FETCH_MATCHING_CANDIDATES";

// For autocomplete job searching
export const AUTOCOMPLETE_JOB_SEARCH = "AUTOCOMPLETE_JOB_SEARCH";

// For initialize recruiter
export const INIT_RECRUITER = "INIT_RECRUITER";

// For add new job in top job data set and removing last job
export const PUSH_TOP_JOB_WITH_CANDIDATES = "PUSH_TOP_JOB_WITH_CANDIDATES";

// For activating published job
export const ACTIVATE_PUBLISHED_JOB = "ACTIVATE_PUBLISHED_JOB";

// For setting & Getting recommend job profiles
export const RECOMMEND_JOB_PROFILES = "RECOMMEND_JOB_PROFILES";

export const ALL_JOBS_COUNT = "DASHBOARD_NUMBERS";

// For update job
export const UPDATE_JOB = "UPDATE_JOB";

// For reset recruiter state
export const RESET_RECRUITER_STATE = "RESET_RECRUITER_STATE";

// When recruiter want to view matching candidate this payload will contain job_id & cv_id
// Job id and cv id is required to view candidate profile
export const CANDIDATE_VIEW_PAYLOAD = "CANDIDATE_VIEW_PAYLOAD";

export const MEMBERS_JOBS_STATISTICS = "MEMBERS_JOBS_STATISTICS";

// For get favorite candidate against job
export const GET_FAVORITE_CANDIDATES = "GET_FAVORITE_CANDIDATES";
// For update favorite candidate against job
export const UPDATE_FAVORITE_CANDIDATE = "UPDATE_FAVORITE_CANDIDATE";

// For containing all candidates
export const ALL_CANDIDATES = "ALL_CANDIDATES";
