import axios, { AxiosResponse } from "axios";
import {
  GET_MATCHING_JOBS,
  SEARCH_WHOLE_SYSTEM_JOBS,
  SKILL_GAP_AGAINST_JOB,
  UPSKILL_COURSES
} from "@/store/urls/candidate";
import {
  SearchAnyJobPayload,
  UpskillCoursesActionPayload
} from "@/store/modules/candidates/interfaces";
import { GetJobMatchActionPayload } from "@/store/modules/common/interfaces";

export const get_matching_jobs = async (
  payload: number
): Promise<AxiosResponse> => {
  return await axios
    .get(`${GET_MATCHING_JOBS}${payload}`)
    .then((response) => response.data);
};

export const upskill_courses = async (
  payload: UpskillCoursesActionPayload
): Promise<AxiosResponse> => {
  return await axios.post(UPSKILL_COURSES, payload);
};

/**
 * Function to get job match against job id
 * @param payload
 */
export const skill_gap_against_job = async (
  payload: GetJobMatchActionPayload
): Promise<AxiosResponse> => await axios.post(SKILL_GAP_AGAINST_JOB, payload);

export const search_whole_system_jobs = async (
  payload: SearchAnyJobPayload
): Promise<AxiosResponse> =>
  await axios.post(SEARCH_WHOLE_SYSTEM_JOBS, payload);
