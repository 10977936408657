export const COMMON_LOADING = "COMMON_LOADING";

export const GET_UNIQUE_SECTORS = "GET_UNIQUE_SECTORS";

export const SET_UNIQUE_SECTORS = "SET_UNIQUE_SECTORS";

export const ROLES = "ROLES";

export const GET_ROLE_BY_NAME = "GET_ROLE_BY_NAME";

export const GET_JOB_BY_ID = "GET_JOB_BY_ID";

export const FETCH_SKILLS_GROUP = "FETCH_SKILLS_GROUP";

export const JOB_SEARCH = "JOB_SEARCH";

// For maintaining different states for job viewing
export const VIEW_JOB_STATE = "VIEW_JOB_STATE";

// For initializing startup data
export const INIT_COMMON_DATA = "INIT_COMMON_DATA";

// For skills search
export const SKILL_SEARCH = "SKILL_SEARCH";

// For reset common state
export const RESET_COMMON_STATE = "RESET_COMMON_STATE";

// For uploading file & fetching skills
export const UPLOAD_FILE = "UPLOAD_FILE";
