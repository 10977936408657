import getters from "@/store/modules/recruiter/getters";
import mutations from "@/store/modules/recruiter/mutations";
import actions from "@/store/modules/recruiter/actions";
import { RecruiterState } from "@/store/modules/recruiter/interfaces";
export default {
  namespaced: true,
  state: (): RecruiterState => ({
    loading: false,
    jobs_posted_loading: false,
    view_candidates_dynamically: {
      active: false,
      loading: false,
      job_id: null,
      job_title: ""
    },
    // Using in job listing page
    jobs_posted: {
      total: 0,
      results: []
    },
    // Using to display jobs in dashboard
    top_jobs_posted: {
      total: 0,
      results: []
    },
    recommend_job_profiles: [],
    // When recruiter want to view matching candidate this payload will contain job_id & cv_id
    // Job id and cv id is required to view candidate profile
    candidate_view_payload: null,
    all_candidates: {
      total: 0,
      results: [],
      filtered_count: -1
    }
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
