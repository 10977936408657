import {
  GET_CANDIDATE_LOADING,
  GET_JOBS_LOADING,
  GET_MATCHING_JOB_BY_ID,
  GET_MATCHING_JOBS,
  GET_TOP_MATCHING_JOB,
  JOB_ID_FOR_UPSKILL,
  SEARCHED_MATCHING_JOBS,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/candidates/constants";
import {
  CandidateState,
  MatchingJob,
  SearchedMatchingJobsPayload
} from "@/store/modules/candidates/interfaces";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";

export default {
  [GET_CANDIDATE_LOADING]: (state: CandidateState): boolean => state.loading,
  [GET_JOBS_LOADING]: (state: CandidateState): boolean => state.jobs_loading,
  [UPSKILL_COURSES_LOADING]: (state: CandidateState): boolean =>
    state.upskill_courses_loading,
  [GET_MATCHING_JOBS]: (state: CandidateState): MatchingJob[] =>
    state.matching_jobs,
  [SEARCHED_MATCHING_JOBS]: (
    state: CandidateState
  ): SearchedMatchingJobsPayload => state.searched_matching_jobs,
  [GET_MATCHING_JOB_BY_ID]:
    (state: CandidateState) =>
    (job_id: number, search = false): MatchingJob | undefined => {
      if (search) {
        return state.searched_matching_jobs.jobs.find(
          (_job) => _job.jid === job_id
        );
      }
      return state.matching_jobs.find((_job) => _job.jid === job_id);
    },
  [GET_TOP_MATCHING_JOB]: (state: CandidateState): MatchingJob | null => {
    if (state.matching_jobs.length > 0) return state.matching_jobs[0];
    return null;
  },
  [JOB_ID_FOR_UPSKILL]: (state: CandidateState): number | null =>
    state.job_id_for_upskill,
  [UPSKILL_COURSES]: (
    state: CandidateState
  ): UpskillCourses.UpskillCoursesRoot | null => state.upskill_courses
};
