export function clean_string(value: string): string {
  return value.trim().toLowerCase();
}

/**
 * Return Regex for email validation
 * @return Regex for email validation. You can use .test function to validate string
 */
function get_email_validator_regex(): RegExp {
  return /^(([^<>()#$%&*{}~=[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

export function get_password_validator_regex(): RegExp {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@~`$!#^()_\-=+%*?&])[A-Za-z\d@~`$!#^()_\-=+%*?&]{3,16}$/;
}

/**
 * Return email rules for input filed
 * Rules are: Email not empty, email is valid again email validator regex
 * @return Array of validations, use with v-text-field => rules prop
 */
export function get_email_rules(): { (value: string): boolean | string }[] {
  return [
    (value: string) => !!value || "Email is required",
    (value: string) =>
      get_email_validator_regex().test(value) || "Invalid Email"
  ];
}

/**
 * Return password rules for input filed
 * Rules are: Password not empty, Password contain 1 lower case, 1 upper case, 1 letter & 1 number must and length between 8-16
 * @return Array of validations, use with v-text-field => rules prop
 */
export function get_password_rules(): { (value: string): boolean | string }[] {
  return [
    (value: string) => !!value || "Password is required",
    (value: string) =>
      (value && value.length > 8) ||
      "Password must contain at least 8 characters",
    (value: string) =>
      (value && value.length < 16) ||
      "Password length should be less than 16 characters"
  ];
}

/**
 * Return Regex for facebook URL validation
 * @return Regex for facebook URl validation. You can use .test function to validate string
 */
export function get_facebook_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Return Regex for instagram URL validation
 * @return Regex for instagram URl validation. You can use .test function to validate string
 */
export function get_instagram_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(m\.instagram|instagram)\.(com)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Return Regex for twitter URL validation
 * @return Regex for twitter URl validation. You can use .test function to validate string
 */
export function get_twitter_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(twitter)\.(com)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Return Regex for linkedin URL validation
 * @return Regex for linkedin URl validation. You can use .test function to validate string
 */
export function get_linkedin_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(linkedin)\.(com)\/(in|pub|public-profile\/in|public-profile\/pub|company|jobs)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Format overall score. Return value will between 0 - 1
 * @param score: number
 */
export function format_overall_score(score: string): number {
  return parseInt((parseFloat(score) * 100).toFixed(0));
}

/**
 * Format overall score. Return value will between 0 - 1
 * @param score: number
 */
export function format_overall_score_v2(score: string): number {
  return parseInt((parseFloat(score) * 100).toString());
}

/**
 * Return initials of name
 * @param name
 */
export function get_name_initials(name: string): string {
  const parts = name.split(" ");
  let initials = "";
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  return initials;
}

/**
 * Validate file type
 * @param file_type => file type to check
 * @param file => File to check
 * @return boolean
 */
export function validate_file_type(file_type: string, file: File): boolean {
  const _file_type = clean_string(file_type);
  let valid: boolean;
  switch (_file_type) {
    case "pdf": {
      valid = file.type === "application/pdf";
      break;
    }
    case "text": {
      valid = file.type === "text/plain";
      break;
    }
    case "word": {
      valid =
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/doc" ||
        file.type === "application/ms-doc" ||
        file.type === "application/msword";
      break;
    }
    default: {
      valid = false;
    }
  }
  return valid;
}

/**
 * Convert file size(bytes) to MBs
 * @param size: bytes
 * @return string => size in MBs
 */
export function get_file_size(size: number): string {
  return (size / (1024 * 1024)).toFixed(2);
}

/**
 * Function to capitalize sentence each word
 * @param text => sentence to capitalize
 */
export function capitalize_text(text: string): string {
  return text.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
}

/**
 * Generate random key length => 5
 */
export function generate_random_key(): number {
  return Math.floor(Math.random() * 99999);
}

/**
 * Function to return random number between 2 numbers
 * @param min
 * @param max
 */
export function get_random_number_between(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function get_allowed_file_types(): string {
  return "text/plain, application/pdf, application/doc, application/ms-doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
}

/**
 * Function for wait
 * @param time => time in seconds
 */
export async function wait_until(time = 3000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, time));
}
