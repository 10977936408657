export interface Roles {
  company_id: number;
  id: number;
  is_super: boolean;
  role_name: string;
  role_name_alias?: string;
  status?: boolean;
  type?: string;
}

export enum HCMSRoles {
  "Educational Institution Representative" = "educational institution representative",
  Individual = "individual",
  Admin = "admin",
  "Recruiter" = "recruiter",
  "Student" = "student"
}
