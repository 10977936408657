import getters from "@/store/modules/candidates/getters";
import mutations from "@/store/modules/candidates/mutations";
import actions from "@/store/modules/candidates/actions";
import { CandidateState } from "@/store/modules/candidates/interfaces";
export default {
  namespaced: true,
  state: (): CandidateState => ({
    loading: false,
    jobs_loading: false,
    matching_jobs: [],
    upskill_courses: null,
    upskill_courses_loading: false,
    job_id_for_upskill: null,
    searched_matching_jobs: {
      jobs: [],
      title: "",
      active: false
    }
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
