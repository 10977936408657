















import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import RootLoader from "@/components/shared/RootLoader.vue";
import {
  ROOT_ERROR,
  ROOT_LOADING,
  ROOT_NOTIFICATION
} from "@/store/modules/root/constants";
import ErrorSnackbar from "@/components/shared/snackbar/ErrorSnackbar.vue";
import NotificationSnackbar from "@/components/shared/snackbar/NotificationSnackbar.vue";

export default Vue.extend({
  name: "App",
  components: { NotificationSnackbar, ErrorSnackbar, RootLoader },
  async created() {
    this.set_root_loading(true);
    // If token not exist and route isn't login
    // Navigate to log in
    // if (!this.get_user_token && this.$route.name !== "login")
    //   await router.push("/");
    this.set_root_loading(false);
  },
  computed: {
    ...mapGetters({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION,
      root_loading: ROOT_LOADING
    })
  },
  methods: {
    ...mapMutations({
      set_root_loading: ROOT_LOADING
    })
  }
});
