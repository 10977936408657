export const GET_CANDIDATE_LOADING = "GET_CANDIDATE_LOADING";
export const SET_CANDIDATE_LOADING = "SET_CANDIDATE_LOADING";

export const GET_JOBS_LOADING = "GET_JOBS_LOADING";
export const SET_JOBS_LOADING = "SET_JOBS_LOADING";

export const GET_MATCHING_JOBS = "GET_MATCHING_JOBS";
export const SET_MATCHING_JOBS = "SET_MATCHING_JOBS";

export const GET_MATCHING_JOB_BY_ID = "GET_MATCHING_JOB_BY_ID";

export const UPSKILL_COURSES = "UPSKILL_COURSES";
export const UPSKILL_COURSES_LOADING = "UPSKILL_COURSES_LOADING";

export const GET_TOP_MATCHING_JOB = "GET_TOP_MATCHING_JOB";

// For holding the job id to upskill course
export const JOB_ID_FOR_UPSKILL = "JOB_ID_FOR_UPSKILL";

// For initializing startup data
export const INIT_CANDIDATE = "INIT_CANDIDATE";

// For getting job match against job id
export const GET_JOB_MATCH_AGAINST_JOB_ID = "GET_JOB_MATCH_AGAINST_JOB_ID";

// For holding searched matching jobs
export const SEARCHED_MATCHING_JOBS = "SEARCHED_MATCHING_JOBS";

// For reset state to initial
export const RESET_CANDIDATE_STATE = "RESET_CANDIDATE_STATE";

export const SEARCH_ANY_JOB = "SEARCH_ANY_JOB";
